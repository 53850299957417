import React, { useState, useEffect } from "react";
import useApi from "storybook-dashboard/utils/fetching";
import { connect } from "react-redux";
import { BSModal, BSBtn } from "traec-react/utils/bootstrap";

import Traec from "traec";
import { getPathChildren } from "traec/utils/nodes";

import { ErrorBoundary } from "traec-react/errors";
import ReportRowErrorBoundary from "./error";
import { Spinner } from "traec-react/utils/entities";
import { BreadCrumb, getProjectProps, loadConvFacts } from "AppSrc/project/utils";
import ReportCategoryItem from "./reportCategoryItem";

import { alertSuccess } from "traec-react/utils/sweetalert";
import { getCommitStatus } from "AppSrc/project/commits/row";
import { reportingPeriodText, dateToText } from "AppSrc/project/report/utils";
import { isAllRequiredSubmitted, Submit } from "AppSrc/project/report/submit";
import { isApprover } from "AppSrc/project/commits/row";
import { setInitialConversions, hasDiff } from "./utils";
import { SetMetaDataFields } from "AppSrc/forms/meta";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";

import { exportToCSV } from "../metrics";

import { isRequiredOnFreq, NotRequiredMsg, ReportPeriodText } from "./reportMetricRow";
import { getScoreValuesByBaseMetric, getChildMetricScores, getChildDocumentsWithStatus, pathHasInputs } from "./utils";

import { useModal } from "storybook-dashboard/components/modal";
import { ValidateAndSubmitModal, SubmitModal } from "./validate";

import CommentThread from "AppSrc/project/comments";

const hasAll = (list) => {
  return list.every((i) => i);
};

const showApprovals = (commit, props) => {
  const { userProjectPermissions, projectId, projectBaseDisciplineMap, projectDisciplines } = props;
  if (!projectBaseDisciplineMap) return false;
  if (!isApprover(userProjectPermissions, commit, projectId, projectBaseDisciplineMap, projectDisciplines))
    return false;
  return Traec.Im.Set(["Pending Approval", "OK for Submission"]).has(commit?.getInPath("status.name"));
};

const setCommitMeta = (commit, meta_json) => {
  if (!commit) {
    console.warn("No commit provided");
    return null;
  }
  console.warn("Saving commit meta", commit?.toJS(), meta_json);
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: commit.get("ref"),
    commitId: commit.getInPath("uid"),
  });
  fetch.updateFetchParams({
    body: {
      meta_json,
    },
  });
  fetch.dispatch();
};

/* ###### COMPONENTS ###### */

const REPORT_STATUS_PROPS = {
  notstarted: { name: "alert", className: "text-danger" },
  incomplete: { name: "alert", className: "text-warning" },
  complete: { name: "check", className: "text-success" },
};

const REPORT_TOOLTIPS = {
  notstarted: "You have not started completing this section",
  incomplete: "You have started entering data but are yet to mark the section as complete",
  complete: "You have marked this section as complete",
};

const APPROVAL_STATUS_PROPS = {
  rejected: { name: "stop", className: "text-danger" },
  unreviewed: { name: "clock", className: "text-secondary" },
  approved: { name: "thumbsup", className: "text-success" },
};

const APPROVAL_TOOLTIPS = {
  rejected: "This section was rejected",
  unreviewed: "This section is pending approval or rejection",
  approved: "This section was approved",
};

function ReportPeriodString({ reportingPeriod }) {
  return reportingPeriod ? reportingPeriodText(reportingPeriod) : "";
}

function ReportDueDateString({ reportDueDate }) {
  console.log("report due date >>", reportDueDate);
  return reportDueDate ? dateToText(reportDueDate, 0) : "";
}

function CategoryTabLabel(props) {
  let { name, _id, show, isSectionComplete, disableInputs, hasInputs, categoryPath, commit } = props;

  // Render approval tabs if this report is for approval
  let for_approval = showApprovals(commit, props);
  let sectionsApproved = commit.getInPath("meta_json._sectionsApproved") || Traec.Im.Map();
  let isSectionApproved = sectionsApproved.get(categoryPath, null);

  let completeState = isSectionComplete ? "complete" : hasInputs ? "incomplete" : "notstarted";
  let approvedState = isSectionApproved === true ? "approved" : isSectionApproved === false ? "rejected" : "unreviewed";

  // Get some parameters depending on the type of report we are approving
  let STATUS_PROPS = for_approval ? APPROVAL_STATUS_PROPS : REPORT_STATUS_PROPS;
  let TOOLTIPS = for_approval ? APPROVAL_TOOLTIPS : REPORT_TOOLTIPS;

  // Get the properties of the icon that we will use
  let section_status = for_approval ? approvedState : completeState;
  let tooltipText = TOOLTIPS[section_status];
  let status = STATUS_PROPS[section_status] || {};

  let iconName = status.name || "alert";
  let iconClass = status.className || "text-warning";

  return (
    <a className={`nav-item nav-link ${show ? "active" : ""}`} data-toggle="tab" href={`#${_id}`}>
      <Tooltip
        animateFill={false}
        html={
          <div className="text-left">
            <p>{tooltipText}</p>
          </div>
        }
      >
        {name} <Octicon name={iconName} className={iconClass} />
      </Tooltip>
    </a>
  );
}

function NavButton({ tabDetails, index, step, label }) {
  let toDetails = index + step >= 0 ? tabDetails.get(index + step) : null;
  //console.log("RENDERING NAV BUTTON", index, label, step, toDetails, tabDetails.toJS())
  if (!toDetails) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-secondary mr-2 mt-0 mb-0 pt-0 pb-0"
      onClick={(e) => {
        // Use jQuery to emulate pressing next link in the tab
        let current = jQuery(".nav-tabs > .active");
        let next = step > 0 ? current.next("a") : current.prev("a");
        next.trigger("click");
      }}
    >
      {label}
    </button>
  );
}

function NavButtons(props) {
  return (
    <ErrorBoundary>
      <div className="float-right m-0">
        <NavButton {...props} step={-1} label="<< Previous section" />
        <NavButton {...props} step={1} label="Next section >>" />
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function ApproveButton({ id, labelText, checkedState, state, setState, checkedClass }) {
  let checked = state === checkedState;
  // onClick={e => setState(checkedState)}
  let _class = checked ? checkedClass || "btn-primary" : "btn-secondary-outline";
  return (
    <React.Fragment>
      <button className={`btn btn-sm mt-0 pt-0 mb-0 pb-0 ml-2 ${_class}`} onClick={() => setState(checkedState)}>
        {labelText}
      </button>
    </React.Fragment>
  );
}

function ApproveButtonSet({ idKey, state, setState }) {
  let _props = { state, setState };
  return (
    <ErrorBoundary>
      <ApproveButton
        id={`${idKey}-on`}
        labelText="Accepted"
        checkedState={true}
        checkedClass={"btn-success"}
        {..._props}
      />
      <ApproveButton
        id={`${idKey}-na`}
        labelText="Unreviewed"
        checkedState={null}
        checkedClass={"btn-secondary"}
        {..._props}
      />
      <ApproveButton
        id={`${idKey}-off`}
        labelText="Requires Revision"
        checkedState={false}
        checkedClass={"btn-danger"}
        {..._props}
      />
    </ErrorBoundary>
  );
}

function CheckCategoryApproved(props) {
  let { categoryPath, commit } = props;

  let sectionsApproved = commit.getInPath("meta_json._sectionsApproved") || Traec.Im.Map();
  let state = sectionsApproved.get(categoryPath, null);
  //console.log("Rendering CheckCategoryApproval", state);

  const setState = (value) => {
    let newSectionsApproved = sectionsApproved.set(categoryPath, value).toJS();
    console.log(`Setting section ${categoryPath} to ${value}`, newSectionsApproved);
    setCommitMeta(commit, { _sectionsApproved: newSectionsApproved });
  };

  return (
    <ErrorBoundary>
      <div className="form-check float-right mb-2 mr-2">
        <label className="form-check-label" htmlFor="categoryComplete">
          <b>Mark section as:</b> <ApproveButtonSet idKey={categoryPath} state={state} setState={setState} />
        </label>
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function CheckCategoryComplete(props) {
  let { setSectionComplete, categoryPath, isSectionComplete, disableInputs, commit } = props;

  // If the report is pending approval then set the section to approved/rejected
  if (showApprovals(commit, props)) {
    return <CheckCategoryApproved {...props} />;
  }

  return (
    <ErrorBoundary>
      <div className="form-check float-right mb-2 mr-2">
        <input
          type="checkbox"
          className="form-check-input"
          id="categoryComplete"
          disabled={disableInputs}
          checked={isSectionComplete}
          onChange={(e) => setSectionComplete(categoryPath, !isSectionComplete)}
        />
        <label className="form-check-label" htmlFor="categoryComplete">
          <b>Mark section as complete</b>
        </label>
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

export function FrequencyAlert(props) {
  let { dueThisReport, from_date, dueDate } = props;
  if (!dueThisReport || !from_date || !dueDate) {
    return null;
  }
  return (
    <div className="alert alert-warning text-center" role="alert">
      Data reported for this section is for the period <br />
      <ReportPeriodText isRequiredPeriodically={props} preamble={" "} />
    </div>
  );
}

export function NotRequiredAlert(props) {
  return (
    <div className="alert alert-warning text-center" role="alert">
      This section is not due in this reporting period <br />
      <NotRequiredMsg {...props} />
    </div>
  );
}

function SectionComments(props) {
  let { trackerId, commitId, categoryPath, commitNodes } = props;
  //console.log("Rendering comment thread", trackerId, commitId, categoryPath, commitNodes);
  return <CommentThread trackerId={trackerId} commitId={commitId} path={categoryPath} commitNodes={commitNodes} />;
}

function CategoryTabContent(props) {
  let { _id, index, tabDetails, show, tree, currentReportingPeriod } = props;

  // If this tab has a reporting frequency then
  let freqDetails = isRequiredOnFreq(tree.get("meta_json"), currentReportingPeriod);
  let notRequiredThisPeriod = (freqDetails || {}).dueThisReport === false;
  if (notRequiredThisPeriod) {
    return (
      <div id={_id} className={`tab-pane fade ${show ? "show active" : ""}`}>
        <NotRequiredAlert {...freqDetails} />
        <NavButtons tabDetails={tabDetails} index={index} />
      </div>
    );
  }

  return (
    <div id={_id} className={`tab-pane fade ${show ? "show active" : ""}`}>
      <FrequencyAlert {...freqDetails} />
      <CategoryTable {...props} asFullTable={true} hideTitleRow={true} />
      <hr />
      <CheckCategoryComplete {...props} />
      <hr />
      <SectionComments {...props} />
      <hr />
      <NavButtons tabDetails={tabDetails} index={index} />
    </div>
  );
}

function TabbedReport(props) {
  let { categoryTrees, sectionsComplete, disableInputs, inputsByCategoryPath, sortKey, sectionsApproved, commit } =
    props;

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  let tabDetails = categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => {
      let name = tree.get("name");
      let categoryPath = tree.get("_path");
      let _id = `path_${categoryPath}`;
      let isSectionComplete = sectionsComplete.get(categoryPath) === true;
      let hasInputs = pathHasInputs(categoryPath, inputsByCategoryPath);
      return { name, _id, tree, categoryPath, isSectionComplete, hasInputs };
    });

  let tabLabels = tabDetails.map((details, i) => (
    <CategoryTabLabel
      key={i}
      {...details}
      disableInputs={disableInputs}
      sectionsApprove={sectionsApproved}
      commit={commit}
      show={i == 0}
    />
  ));

  let tabContents = tabDetails.map((details, i) => (
    <CategoryTabContent key={i} index={i} {...props} {...details} tabDetails={tabDetails} show={i == 0} />
  ));

  return (
    <ErrorBoundary>
      <div className="nav nav-tabs" role="tablist">
        {tabLabels}
      </div>

      <ErrorBoundary>
        <div className="tab-content">{tabContents}</div>
      </ErrorBoundary>
    </ErrorBoundary>
  );
}

function CategoryTableWrapper(props) {
  let { asFullTable, tree, disableInputs } = props;

  // If not rendering a full table then just render children directly
  if (!asFullTable) {
    return <ReportCategoryItem {...props} />;
  }
  // Wrap the children (<tr> elements) in a table
  return (
    <ErrorBoundary>
      <CategoryHelpText tree={tree} />
      <table className="table table-sm table-hover">
        <ReportTableHeader />
        <tbody>
          <ReportCategoryItem {...props} />
        </tbody>
      </table>
    </ErrorBoundary>
  );
}

function CategoryHelpText({ tree }) {
  let helpText = tree.getInPath("meta_json.helpText");
  if (!helpText) {
    return null;
  }
  return (
    <div class="alert alert-primary mt-2 mb-2" role="alert">
      {helpText}
    </div>
  );
}

function CategoryTable(props) {
  let { tree, inputErrors, disableInputs, updateError, asFullTable, hideTitleRow } = props;

  // Pass through only validation errors for this issue/category
  let path = tree.get("_path");
  let _inputErrors = (inputErrors || Traec.Im.Map()).get(path);

  // Clean up the props to avoid passing unneccessary stuff that will cause a category to update (for performance)
  let _props = { ...props };
  let keysToRemove = [
    "tabDetails",
    "inputErrors",
    "reportScores",
    "scoreValues",
    "inputsByCategoryPath",
    "metricScores",
  ];
  for (let key of keysToRemove) {
    delete _props[key];
  }

  return (
    <CategoryTableWrapper
      {..._props}
      asFullTable={asFullTable}
      tree={tree}
      path={path}
      categoryPath={path} // Keep a record of the category/issue path (for section complete and validation)
      inputErrors={_inputErrors}
      hideTitleRow={hideTitleRow}
      disableInputs={disableInputs}
      updateInputErrors={updateError}
    />
  );
}

function CategoryTableRows(props) {
  let { categoryTrees, sortKey } = props;

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  return categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => (
      <ReportRowErrorBoundary key={i} msg={<td colSpan="100%">Error loading issue section: {tree.get("name")}</td>}>
        <CategoryTable {...props} tree={tree} hideTitleRow={false} asFullTable={false} />
      </ReportRowErrorBoundary>
    ));
}

function ReportTableHeader(props) {
  const titles = [
    { name: "Metric", width: "50%", className: "text-left" },
    { name: "Units", width: "5%", className: "text-left" },
    { name: "Value", width: "10%", className: "text-left" },
    { name: "Comments", width: "25%", className: "text-left" },
    { name: "N/A", width: "5%" },
    { name: "Past data", width: "5%", className: "text-nowrap" },
  ];
  const headCols = titles.map((title, i) => (
    <th
      key={i}
      width={title.width}
      className={`${title.className} border-top-0` || "text-center border-top-0"}
      scope="col"
    >
      {title.name}
    </th>
  ));
  return (
    <thead>
      <tr>{headCols}</tr>
    </thead>
  );
}

function TableReport(props) {
  let { commit, isLoading } = props;
  if (isLoading || !commit) {
    return null;
  }

  return (
    <table className="table table-sm table-hover">
      <ReportTableHeader />
      <tbody>
        <CategoryTableRows {...props} sectionsComplete={Traec.Im.Map()} />
      </tbody>
    </table>
  );
}

function ReportHeadline({ commit, disableInputs }) {
  return null;
  let headline = null;
  if (!disableInputs) {
    headline = null; // "Report Due"
  } else if (disableInputs && !getCommitStatus(commit)) {
    headline = null; // "Report Due"
  } else {
    headline = "Report Submitted";
  }
  return headline ? <h3>{headline}</h3> : null;
}

function ReportCommitForm(props) {
  let { commitId, isLoading, inputErrors, needsRevision, revalidate } = props;

  let { setModal } = useModal();
  let { data: report } = useApi(commitId ? `/api/commit/${commitId}/` : null);

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary title="Error loading submission button">
      <div className="mt-2" style={{ clear: "both" }} />
      <Submit
        {...props}
        postCommit={(e) => setModal(<ValidateAndSubmitModal reportId={commitId} method="POST" />)}
        patchCommit={(e) => setModal(<ValidateAndSubmitModal reportId={commitId} method="PATCH" />)}
        revalidate={revalidate}
        needsRevision={needsRevision}
        inputErrors={inputErrors}
      />
      <div style={{ clear: "both" }} className="mb-2" />

      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

const REPORT_TYPES = {
  classic: TableReport,
  tabbed: TabbedReport,
};

function ReportBody(props) {
  const { commit, project, tracker, convFactorDetails, commitNodes, hasConversionMap } = props;

  // Data needed to render the report
  let required = [
    commit,
    project,
    tracker,
    convFactorDetails,
    convFactorDetails.convFactorMap,
    commitNodes,
    hasConversionMap,
  ];

  // Ensure we have everything we need to render the report
  if (!hasAll(required)) {
    //console.log("ReportBody unable to render. Missing required data...", required);
    return null;
  }

  // Get the format of the report to render (tabbed or classic table)
  let report_layout = commit.getInPath("meta_json.report_layout") || "classic";
  let ReportComponent = REPORT_TYPES[report_layout] || TableReport;

  //console.log("CALLING render on ReportBody");
  return (
    <ErrorBoundary title="Error loading report">
      {/* Set defined meta-data input fields for the form */}
      <ReportMetaModal {...props} modalTitle={"Additiona meta-data"} btnText="Additional meta-data" />
      {/* Render the table for all inputs */}
      <ReportComponent {...props} />
      {/* Render the submit for approval panel */}
      <ReportCommitForm {...props} />
    </ErrorBoundary>
  );
}

export const isMetaComplete = (meta) => {
  if (!meta) {
    return false;
  }
  let input_details = meta?.get("input_details") || Traec.Im.Map();
  let fields = input_details.get("fields") || Traec.Im.List();
  console.log("Checking if report meta complete", fields?.toJS(), meta?.toJS());
  return fields.every((i) => meta?.get(i?.get("header")));
};

function ReportMetaModal(props) {
  let { commit, cref, modalTitle, btnText, revalidate, disableInputs } = props;

  if (!commit) {
    return null;
  }

  // Get the fields and filter any that have "hideInReport: true"
  let input_fields = (commit.getInPath("meta_json.input_details.fields") || Traec.Im.List()).filter(
    (field) => !field.get("hideInReport")
  );

  if (!input_fields.size) {
    return null;
  }

  let modalId = "ReportMetaDataModal";

  let [shown, setShown] = useState(isMetaComplete(commit?.get("meta_json")));

  useEffect(() => {
    if (!shown) {
      $(`#${modalId}`).modal("show");
      setShown(true);
    }
  });

  return (
    <ErrorBoundary>
      <BSModal
        id={modalId}
        title={modalTitle || "Meta-data"}
        body={
          <ErrorBoundary>
            <p>
              Please complete required meta-data in the form below. Once in the survey, you can edit this data by
              clicking on the "Meta-data" button at the top of the report.
            </p>
            <hr />
            <SetMetaDataFields
              disabled={disableInputs}
              hideAdmin={true}
              //hideSave={true}
              //saveOnBlur={true}
              saveMetaFetchProps={{
                handler: "tracker_ref_commit",
                method: "patch",
                params: {
                  trackerId: commit.get("tracker"),
                  refId: commit.get("ref"),
                  commitId: commit.get("uid"),
                },
                successHook: (data) => {
                  if (revalidate) {
                    revalidate();
                  }
                  let _meta = Traec.Im.fromJS(data || {})?.get("meta_json");
                  console.log("Validating saved meta-data", _meta?.toJS());
                  if (isMetaComplete(_meta)) {
                    console.log("Submitted meta-data form is complete.  Closing modal");
                    $(`#${modalId}`).modal("hide");
                    alertSuccess({
                      title: "Meta-data complete",
                      text: "Your meta-data information is saved.  You may complete the report.",
                      iconType: "success",
                    });
                  } else {
                    alertSuccess({
                      title: "Meta-data not complete",
                      text: "You must complete all fields in the meta-data form before you can submit this report",
                      iconType: "error",
                    });
                  }
                },
              }}
              metaJson={commit.get("meta_json")}
            />
          </ErrorBoundary>
        }
      />
      <BSBtn
        noFloatRight={true}
        primaryOff={true}
        extra_className={"mb-3 btn-info"}
        text={btnText || "Edit meta-data"}
        onClick={() => $(`#${modalId}`).modal("show")}
      />
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function ExportMetrics(props) {
  return (
    <ErrorBoundary>
      <p className="float-right" style={{ cursor: "pointer" }} onClick={() => exportToCSV(props)}>
        <Octicon name="desktop-download" /> Export report metrics
      </p>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

class ProjectReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedRootCommitBranches: false,
      fetchedConversionFactors: false,
      fetchedValues: false,
      fetchedUrls: {},
      showDocs: false,
      nameFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      commitFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      isLoading: true,
      disableInputs: false,
      inputErrors: Traec.Im.Map(),
      sectionsComplete: Traec.Im.Map(),
      sectionsApproved: Traec.Im.Map(),
      madeConversionMap: false,
      doneInitialValidation: false,
      convFactorDetails: {},
    };

    this.requiredFetches = [
      new Traec.Fetch("project_reporting_periods", "list"),
      new Traec.Fetch("project_permission", "list"),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("tracker_ref_commit", "list"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker_commit_value", "list"),
      new Traec.Fetch("tracker_commit_branch", "list"),
      new Traec.Fetch("tracker_commit_convfactor", "list"),
    ];

    // action bindings
    this.updateError = this.updateError.bind(this);
    this.needsRevision = this.needsRevision.bind(this);
    this.updateSectionComplete = this.updateSectionComplete.bind(this);
    this.setSectionComplete = this.setSectionComplete.bind(this);
    this.doInitialValidation = this.doInitialValidation.bind(this);
    this.validateSectionComplete = this.validateSectionComplete.bind(this);
  }

  /**********************
    COMPONENT METHODS
  **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate(prevProps) {
    Traec.fetchRequiredFor(this);
    let {
      isResponsible,
      hasConversionMap,
      commitNodes,
      hasScoreValues,
      commitId,
      categoryTrees,
      conversionFactors,
      baseMetrics,
    } = this.props;
    let { disableInputs, madeConversionMap, doneInitialValidation, convFactorDetails } = this.state;

    this.setIsLoading();

    if (conversionFactors && commitId && convFactorDetails && !convFactorDetails.convFactorMap) {
      // Load the conversion factors into state
      let state = Traec.Im.Map()
        .setInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`, conversionFactors)
        .setInPath(`entities.baseMetrics.byId`, baseMetrics);
      // Set conversion factor map in state to avoid re-calculating in mapStateToProps
      //console.log("ProjectReport setState: {convFactorDetails:}");
      this.setState({
        convFactorDetails: {
          convFactRef: null,
          convFactorMap: loadConvFacts(state, commitId),
        },
      });
    }

    // Construct the initial conversion factor maps
    let _required = [
      !madeConversionMap,
      !hasConversionMap,
      hasScoreValues,
      commitNodes,
      convFactorDetails,
      convFactorDetails.convFactorMap,
    ];
    if (hasAll(_required)) {
      setInitialConversions(commitId, commitNodes, convFactorDetails);
      this.setState({
        madeConversionMap: true,
      });
    }

    // Do an initial validation of the form after loading the scoreValues
    if (!doneInitialValidation && categoryTrees && hasScoreValues && commitNodes) {
      this.doInitialValidation();
    }

    // If we are responsible and the form is hidden then turn it on
    let shouldHide = false;
    if (!isResponsible) {
      shouldHide = true;
    }
    // If we are on a past-commit then hide the inputs (unless we are revising it)
    if (this.isOnHold() || (!this.needsRevision() && !!this.props.match.params._commitId)) {
      //console.log("ProjectReport shouldHide=true because", this.isOnHold(), this.needsRevision(), (!this.needsRevision() && !!(this.props.match.params._commitId)))
      shouldHide = true;
    }

    // Set the state based on the outcome
    if (disableInputs !== shouldHide) {
      //console.log("ProjectReport setState: {disableInputs: shouldHide}");
      this.setState({ disableInputs: shouldHide });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      hasDiff(this.props, nextProps, null) || // "ProjectReport props"
      hasDiff(this.state, nextState, null) // "ProjectReport state"
    );
  }

  setIsLoading() {
    let { categoryTrees } = this.props;
    if (!categoryTrees || categoryTrees.length === 0) {
      return null;
    }
    if (categoryTrees && this.state.isLoading) {
      //console.log("ProjectReport setState {isLoading: false}");
      this.setState({ isLoading: false });
    }
  }

  isOnHold() {
    let { commit } = this.props;
    let commitStatus = getCommitStatus(commit);
    return commitStatus && commitStatus.startsWith("Not for");
  }

  needsRevision() {
    let { tracker, cref, isResponsible, commit } = this.props;
    let needsRevision = false;
    if (tracker && cref && commit) {
      needsRevision = isResponsible && commit.getInPath("status.name") === "Requires Revision";
    }
    //console.log("ProjectReport needsRevision", needsRevision, commit?.toJS())
    return needsRevision;
  }

  /**********************
    MENU METHODS
  **********************/

  getReportLayout() {
    let { commit } = this.props;
    return (commit ? commit.getInPath("meta_json.report_layout") : "classic") || "classic";
  }

  doInitialValidation() {
    let { categoryTrees, dispatch, commit, revalidateCount, currentReportingPeriod } = this.props;
    let { disableInputs } = this.state;

    console.log("Doing initial validation of loaded data for form", disableInputs);

    let sectionsComplete = commit.getInPath("meta_json._sectionsComplete") || Traec.Im.Map();
    let sectionsApproved = commit.getInPath("meta_json._sectionsApproved") || Traec.Im.Map();

    // In case a section has been deleted and doesnt exist anymore
    let categoryPaths = categoryTrees.reduce((acc, cur) => acc.add(cur.get("_path")), Traec.Im.Set());
    sectionsComplete = sectionsComplete.filter((v, k) => categoryPaths.has(k));
    sectionsApproved = sectionsApproved.filter((v, k) => categoryPaths.has(k));

    // Double check that the section is not required in this period - mark as complete if not required
    categoryTrees.toList().map((tree) => {
      let notDueThisReport =
        (isRequiredOnFreq(tree.get("meta_json"), currentReportingPeriod) || {}).dueThisReport === false;
      if (notDueThisReport) {
        sectionsComplete = sectionsComplete.set(tree.get("_path"), true);
      }
      if (sectionsComplete.get(tree.get("_path")) === true) {
        return true;
      }
    });

    this.setState({
      doneInitialValidation: true,
      sectionsComplete,
      sectionsApproved,
    });

    console.log("DISPATCHING REVALIDATE COUNT", revalidateCount);
    dispatch({
      type: "UI_SET_IN",
      payload: { revalidateCount: revalidateCount + 1 },
      stateParams: {
        itemPath: `reports.${commit.get("uid")}`,
      },
    });
  }

  updateSectionComplete(categoryPath, sectionsComplete = null, mustHaveInputs = false) {
    console.log("Determining if section has all required fields complete", categoryPath);
    let {
      commitNodes,
      metricScores,
      baseMetrics,
      currentReportingPeriod,
      scoreValues,
      documents,
      documentStatuses,
      categoryTrees,
    } = this.props;
    sectionsComplete = sectionsComplete || this.state.sectionsComplete;

    let tree = categoryTrees.filter((i) => i.get("_path") == categoryPath).first();
    if (tree) {
      let freqDetails = isRequiredOnFreq(tree.get("meta_json"), currentReportingPeriod);
      if ((freqDetails || {}).dueThisReport === false) {
        return sectionsComplete.set(categoryPath, true);
      }
    }

    // Always have a valid Map to avoid errors
    scoreValues = scoreValues || Traec.Im.Map();

    // Get the metricscores and baseMetricIds that are under this category/issue
    let categoryMetricScores = getChildMetricScores(categoryPath, commitNodes, metricScores);
    let baseMetricIds = Traec.Im.Set(categoryMetricScores.map((i) => i.getInPath("metric.uid") || i.get("metric")));

    // Get the documents and documentstatuses that are under this category/issue
    let categoryDocumentStatuses = getChildDocumentsWithStatus(categoryPath, commitNodes, documents, documentStatuses);

    // Get the input score values by BaseMetricId
    let categoryScoreValues = getScoreValuesByBaseMetric(scoreValues, baseMetricIds);

    let complete = false;
    if (mustHaveInputs && !categoryScoreValues.size) {
      complete = false;
    } else {
      // If we have inputs then check all required is submitted
      complete = isAllRequiredSubmitted(
        categoryScoreValues,
        categoryMetricScores,
        baseMetrics,
        currentReportingPeriod,
        categoryDocumentStatuses
      );
    }

    // Log status
    console.log(
      "Setting cateogory at path complete status",
      categoryPath,
      Traec.Im.isImmutable(complete) ? complete.toJS() : complete
    );
    return sectionsComplete.set(categoryPath, complete);
  }

  validateSectionComplete(categoryPath) {
    let { inputErrors } = this.state;
    let status = false;

    let errors = inputErrors.get(categoryPath);
    if (errors && errors.size) {
      // First check there are no outstanding input errors
      console.warn("Section has errors - cannot mark as complete", categoryPath, errors.size, errors.toJS());
      let error_lines = errors
        .map((value, key, i) => `${key}`)
        .toList()
        .join("\n");
      alert(`Please correct input errors:\n${error_lines}`);
      status = errors;
    } else {
      // Check that all required fields are complete
      status = Traec.Im.fromJS(this.updateSectionComplete(categoryPath).get(categoryPath));
      if (!(status === true)) {
        console.warn("Section has required fields to complete - cannot mark as complete", categoryPath, status.toJS());
        alert(`Please complete the mandatory fields:\n${status.join("\n")}`);
      }
    }
    return status;
  }

  setSectionComplete(categoryPath, value) {
    let { commit } = this.props;
    console.log("Toggling section complete", categoryPath, value);
    let { sectionsComplete, inputErrors } = this.state;

    // If setting section as complete then do some validation first
    if (value === true) {
      value = this.validateSectionComplete(categoryPath);
    }

    let _sectionsComplete = sectionsComplete.set(categoryPath, value);
    console.log("Setting section complete", _sectionsComplete.toJS());
    this.setState({ sectionsComplete: _sectionsComplete });

    setCommitMeta(commit, {
      _sectionsComplete: _sectionsComplete?.toJS(),
    });
  }

  updateError(metricName, value, isRequiredInPeriod, categoryPath) {
    let { inputErrors } = this.state;
    let _categoryErrors = Traec.Im.Map();

    categoryPath = categoryPath || "";
    let categoryErrors = inputErrors.get(categoryPath) || Traec.Im.Map();

    if (typeof value === "number" || value == null) {
      _categoryErrors = categoryErrors.delete(metricName);
    } else {
      _categoryErrors = categoryErrors.set(metricName, Traec.Im.Map({ name: metricName, value: value }));
    }

    let _inputErrors = inputErrors.set(categoryPath, _categoryErrors);

    console.log("Updating input errors for category", categoryPath);
    if (this.getReportLayout() == "classic") {
      this.setState({
        inputErrors: _inputErrors,
        sectionsComplete: this.updateSectionComplete(categoryPath),
      });
    } else {
      this.setState({
        inputErrors: _inputErrors,
      });
    }
  }

  render() {
    const { commit, company, project, cref, isRootRef, currentReportingPeriod } = this.props;
    let { isLoading, inputErrors, sectionsComplete, sectionsApproved, disableInputs, convFactorDetails } = this.state;

    let status = getCommitStatus(commit);
    status = status ? ` | ${status}` : null;

    if (isLoading) {
      return (
        <Spinner
          title="Loading..."
          explanation={"Loading report..."}
          timedOutComment={"Still loading, please be patient..."}
        />
      );
    }

    console.log("Rendering ProjectReport with disableInputs", disableInputs);

    return (
      <ErrorBoundary>
        <ReportHeadline commit={commit} disableInputs={disableInputs} />
        <h5>
          <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />
        </h5>
        <p>
          Reporting period:{" "}
          <b>
            <ReportPeriodString reportingPeriod={currentReportingPeriod} />
            {status}
          </b>
        </p>
        <p>
          Report due on:
          <b>
            {" "}
            <ReportDueDateString reportDueDate={commit?.get("due_date")} />
          </b>
        </p>

        <ExportMetrics {...this.props} />

        {/* Render the loading spinning wheel */}
        <ReportBody
          {...this.props}
          inputErrors={inputErrors}
          needsRevision={this.needsRevision}
          updateError={this.updateError}
          disableInputs={disableInputs}
          sectionsComplete={sectionsComplete}
          sectionsApproved={sectionsApproved}
          setStateRoot={this.setState}
          setSectionComplete={this.setSectionComplete}
          convFactorDetails={convFactorDetails}
          revalidate={this.doInitialValidation}
        />
      </ErrorBoundary>
    );
  }
}

const cleanCurrentObject = (documentStatus, commitsToKeep) => {
  let commitId = documentStatus.getInPath("current_object.commit");
  if (!commitId || commitsToKeep.has(commitId)) {
    return documentStatus;
  }
  return documentStatus.set("current_object", null);
};

const mapStateToProps = (state, ownProps) => {
  let { _commitId } = ownProps.match.params;
  let { projectId, refId, commitId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, rootRef, isRootRef } = getProjectProps(
    state,
    projectId,
    refId
  );

  let commit = _commitId
    ? state.getInPath(`entities.commits.byId.${commitId}`)
    : cref
    ? cref.get("latest_commit")
    : null;
  commitId = commit?.get("uid");

  // Get the root tree to start with
  let rootTree = commit ? commit.get("tree_root") : null;
  let rootTreeId = rootTree ? rootTree.get("uid") : null;

  // Get all of the trees that are children of the rootTree (edges)
  let commitNodes = commitId ? state.getInPath(`entities.commitNodes.${commitId}`) : null;

  // To force the component to reload when the re-validate button is pressed
  let revalidateCount = state.getInPath(`ui.reports.${commitId}.revalidateCount`) || 0;
  //console.log("EXECUTING mapStateToProps FOR ProjectReport", revalidateCount);

  // Get the root children
  let pathRoot = null;
  let categoryTrees = null;
  if (commitNodes) {
    pathRoot = commitNodes.get("pathRoot");
    categoryTrees = getPathChildren(state, pathRoot, commitNodes, "trees").filter((i) => i);
  }

  // Load existing values input for this commit
  let scoreValues = commitId ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) : null;
  let hasScoreValues = state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) != null;

  // Get metric scores from Redux (filter down to only the metric scores used in this Report)
  let metricScores = state.getInPath("entities.metricScores.byId") || Traec.Im.Map();
  let reportMetricScoreIds = Traec.Im.Set(
    ((commitNodes || Traec.Im.Map()).get("byPath") || Traec.Im.Map())
      .filter((i) => i.get("type") == "metricscore")
      .toList()
      .map((i) => i.get("uid"))
  );
  metricScores = metricScores.filter((value, id) => reportMetricScoreIds.has(id));

  // Get BaseMetrics
  let conversionFactors = state.getInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`);
  let baseMetrics = state.getInPath("entities.baseMetrics.byId");

  // Get inputs for each Category
  let categoryPathMap = (categoryTrees || Traec.Im.List()).reduce(
    (acc, cur) => acc.set(cur.get("_path"), cur),
    Traec.Im.Map()
  );
  let inputsByCategoryPath = categoryPathMap.map((tree, categoryPath) => {
    let categoryMetricScores = getChildMetricScores(categoryPath, commitNodes, metricScores);
    return categoryMetricScores
      .map((ms) => state.getInPath(`entities.commitEdges.byId.${commitId}.scoreValues.${ms.get("uid")}`))
      .filter((i) => i);
  });

  // Get the project reporting periods
  let projectReportingPeriods = crefId
    ? state.getInPath(`entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`)
    : null;
  if (!projectReportingPeriods) {
    projectReportingPeriods = state.getInPath(`entities.projectReportingPeriods.byId.${projectId}`);
  }

  let currentReportingPeriodId = commit ? commit.get("reporting_period") : null;
  let currentReportingPeriod = projectReportingPeriods ? projectReportingPeriods.get(currentReportingPeriodId) : null;

  // Get a map of Project Disciplines
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`) || Traec.Im.List();
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Traec.Im.Map());

  // ONLY ALLOW RESPONSIBLE PEOPLE OF THIS REPORT
  // Get the project permissions for this user
  let userProjectPermissions =
    state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`) || Traec.Im.Map();
  let isResponsible = userProjectPermissions.get("is_admin");
  let isProjectAdmin = userProjectPermissions.get("is_admin");

  if (commit && !isResponsible) {
    let responsibleDiscipline = commit.get("discipline");
    let userDisciplines = userProjectPermissions.get("baseDisciplineIds") || Traec.Im.List();
    isResponsible = userDisciplines.contains(responsibleDiscipline);
  }

  // Get the initial conversion map (to avoid commponents hitting the server on the first page load)
  let hasConversionMap = state.hasIn(["ui", "childConversions", commitId]);

  // sort alphabetically by default
  let sortKey = commit?.getInPath("meta_json.sortKey") || "name";

  // Documents and DocumentStatus objects
  let commitsToKeep = Traec.Im.Set([null, commitId]);
  let documents = state.getInPath("entities.documents.byId");
  let documentStatuses = state
    .getInPath("entities.documentstatus.byId")
    ?.map((i) => cleanCurrentObject(i, commitsToKeep));

  // Add this to props
  return {
    projectId,
    project,
    company,
    trackerId,
    tracker,
    cref,
    refId: crefId,
    commit,
    commitId,
    isRootRef,
    rootTree,
    rootTreeId,
    rootTreePath: pathRoot,
    commitNodes,
    categoryTrees,
    scoreValues,
    hasScoreValues,
    metricScores,
    baseMetrics,
    projectReportingPeriods,
    currentReportingPeriod,
    userProjectPermissions,
    isResponsible,
    isProjectAdmin,
    trackerRootRef: rootRef,
    projectDisciplines,
    projectBaseDisciplineMap,
    conversionFactors,
    hasConversionMap,
    inputsByCategoryPath,
    revalidateCount,
    sortKey,
    documents,
    documentStatuses,
  };
};

export default connect(mapStateToProps)(ProjectReport);
