import React, {useState} from "react";
import { connect } from "react-redux";
import Traec from "traec";

import * as dashboardUtils from "AppSrc/dashboards/utils";
// import SustainabilityPanel from "AppSrc/dashboards/sustainabilityPanel";
import * as reporting from "AppSrc/dashboards/reportingPeriods";
import { ColorScale } from "AppSrc/dashboards/colorScale";
import ProjectReportCommitResults from "AppSrc/dashboards/lists/projectCommits";
import {ReportingPeriodRangePicker} from "storybook-dashboard/dashboard/components/reportingPeriodRangePicker";

import DashboardComponents from "./dashboard"

import { BSBtnDropdown } from "traec-react/utils/bootstrap/";
import { ErrorBoundary } from "traec-react/errors";

import { BreadCrumb, getProjectProps } from "AppSrc/project/utils";

import {
  ProjectPermission,
  getProjectPermissions,
  projectPermissionFilter,
  projectPermissionCheck
} from "traec/utils/permissions/project";

import { PermissionWarning } from "traec/utils/permissions/project";
import Moment from "moment";
import { parseReportingPeriods } from "AppSrc/dashboards";
import Swal from "sweetalert2";
import { Spinner } from "traec-react/utils/entities";
import Im from "traec/immutable";
import { fetchIndicatorExport } from "storybook-dashboard/dashboard/export/project/fetch"


const getMomentFromLocalStorageOrDefault = (key, defaultValue = null) => {
  let date = localStorage.getItem(key);
  console.log(`Reading localStorage key: ${key}`, date);
  if (date) {
    date = Moment(date);
    return date.isValid() ? date : defaultValue;
  }
  return defaultValue;
};

function ReportStatusTable(props) {
  let { isRootRef } = props;
  if (!isRootRef) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="row">
        <ErrorBoundary>
          <ProjectReportCommitResults {...props} statusOnly={true} />
        </ErrorBoundary>
      </div>
    </React.Fragment>
  );
}

function IndicatorWarning({ indicators }) {
  if (indicators && indicators.size == 0) {
    return (
      <div className="alert alert-warning">
        <strong>No Indicators setup</strong>
        <br />
        This dashboard does not have any indicators setup yet. You will likely see minimal or no content on the
        dashboard. Request an administrator of this to setup some indicators to display the dashboard data.
      </div>
    );
  }
  return null;
}

const getPreUpdateHook = _this => {
  const preUpdateHook = args => {
    let { fromDate, toDate } = _this.state;
    let { isCumulative } = _this.props;
    args.refId = args.isRootRef ? null : args.crefId; // args.isRootRef ? args.rootRefId : args.crefId
    args.fromDate = fromDate.format("YYYY-MM-DD");
    args.toDate = toDate
      .clone()
      .add(1, "days")
      .format("YYYY-MM-DD");
    args.summary_cumulation_period = isCumulative ? "total" : null;
    args.include_summary = false;
    return args;
  };
  return preUpdateHook;
};


class ProjectHome extends React.Component {
  constructor(props) {
    super(props);

    // Try to get the from and to dates from localStorage
    const { _projectId: projectId, _refId: refId } = props.match.params;

    let fromDate = getMomentFromLocalStorageOrDefault(
      `fromDate:${projectId}_${refId}`,
      new Moment().subtract(8, "months").startOf("day")
    );
    let toDate = getMomentFromLocalStorageOrDefault(`toDate:${projectId}_${refId}`, new Moment().endOf("day"));

    this.state = {
      fetchedReportingPeriods: false,
      fetchedCommitTargets: false,
      parsedReportingPeriods: false,
      iconActivated: false,
      indicatorActivated: false,
      detailedIconChart: {},
      indicatorChart: {},
      iconColors: {},
      showColorScale: false,
      indicatorData: null,
      fromDate,
      toDate,
      cumulation: "total",
      prevRefId: null,
      currentRefId: null,
      deselctIndicatorPanel: false,
      selectedSustainabilityIssue: null,
      selectedIndicators: Im.List(),
      filters: new Im.Map()
    };

    this.requiredFetches = [
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("tracker_commit_target", "list"),
      new Traec.Fetch("tracker_commit_indicator", "list"),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("project_permission", "list"),
      new Traec.Fetch("project_reporting_periods", "list", null, { preUpdateHook: getPreUpdateHook(this) }),
      new Traec.Fetch("tracker_commit_branch", "list")
    ];

    // action bindings
    this.getIndicatorColors = dashboardUtils.getIndicatorColors.bind(this);
    this.generateIndicatorChart = dashboardUtils.generateIndicatorChart.bind(this);
    this.toggleColorScale = this.toggleColorScale.bind(this);
    this.refresh_dashboard = this.refresh_dashboard.bind(this);

    // Bindings for fetching
    this.shouldComponentReFetch = reporting.shouldComponentReFetch.bind(this);
    this.setDateRangeValue = reporting.setDateRangeValue.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.dispatch_data = this.dispatch_data.bind(this);
    this.setSelectedSustainabilityIssue = this.setSelectedSustainabilityIssue.bind(this);
    this.setSelectedIndicator = this.setSelectedIndicator.bind(this);
  }

  /**********************
   COMPONENT METHODS
   **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);

    let { projectReportingPeriods } = this.props;
    this.fetchAndParse(projectReportingPeriods, null, true, "project");
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the user dashboard/refId has changed and deselect indicator panel if true
    if (this.state.currentRefId === this.props.refId) {
      if (this.state.deselctIndicatorPanel === true) {
        this.setState({ deselctIndicatorPanel: false });
      }
    } else if (this.state.currentRefId !== this.props.refId) {
      this.setState({ prevRefId: prevState.currentRefId });
      this.setState({ currentRefId: this.props.refId });
      this.setState({ deselctIndicatorPanel: true });
    }

    Traec.fetchRequiredFor(this);

    // Check if we should re-parse the project reporting data
    let { projectReportingPeriods, refId, latestCommitTargets, projectId } = this.props;

    if (latestCommitTargets && !latestCommitTargets.equals(prevProps.latestCommitTargets)) {
      this.setState({ parsedReportingPeriods: false });
    }

    let shouldReFetch = this.shouldComponentReFetch(
      projectReportingPeriods,
      { fromDate: null, toDate: null },
      prevProps.refId,
      prevProps.isCumulative
    );
    if (shouldReFetch) {
      this.setState({
        fetchedReportingPeriods: false,
        parsedReportingPeriods: false
      });
    }

    let reParseData =
      reporting.shouldDashboardReparse(
        prevProps.projectId,
        prevProps.projectReportingPeriods,
        projectId,
        projectReportingPeriods,
        prevProps,
        this.props
      ) || prevProps.refId !== refId;

    //console.log("COMPONENT UPDATED", projectReportingPeriods, reParseData)
    this.fetchAndParse(projectReportingPeriods, latestCommitTargets, reParseData, "project");
  }

  /**********************
   ACTIONS
   **********************/

  refresh_dashboard(e) {
    e.preventDefault();
    let { trackerId, crefId: refId, isRootRef } = this.props;
    let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

    // Data to send to the dispatch
    let formData = new FormData();
    formData.append("type", isRootRef ? "RECALC_PROJECT_CACHE" : "RECALC_REF_CACHE");
    formData.append("payload", JSON.stringify(isRootRef ? {} : { refId }));
    fetch.updateFetchParams({ body: formData });

    Swal.queue([
      {
        title: "Recalculating dashboard",
        confirmButtonText: "Start re-calculation",
        html:
          "<p>This will commence a re-calculation of your dashboard data from the initial report up until the lastest period.</p>  <p>This may take a while (up to 15 minutes) depending on the size of your project and number of metrics.</p><p>You can keep working normally, the dashboard will update in the background.  You must refresh your browser page to reload the new calculated dashboard data.</p>",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch()
            .then(response => {
              if (!response.ok) {
                throw response;
              }
              return response.json();
            })
            .then(data => {
              Swal.insertQueueStep({
                showConfirmButton: false,
                showCancelButton: false,
                title: "Recalculation started",
                html: `<p>You must refresh your browser in the next 30 minutes to load the new dashboard data.</p>`
              });
            })
            .catch(err => {
              let non_field_errors = err.non_field_errors
                ? err.non_field_errors.map(i => `<li>${i}</li>`).join("")
                : "";
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                html: `<p>There was an error requesting recalculation.</p><ul>${non_field_errors}</ul>`
              });
            });
        }
      }
    ]);
  }

  toggleColorScale(e) {
    e.preventDefault();
    this.setState({ showColorScale: !this.state.showColorScale });
  }

  fetchAndParse(reportingPeriods, targets, reParseData, reportType = "project") {
    let { isCumulative } = this.props;
    if (reportingPeriods && (!this.state.parsedReportingPeriods || reParseData)) {
      //Parsing for Icon Charts
      let data = parseReportingPeriods(
        reportingPeriods,
        targets,
        this.state.fromDate,
        this.state.toDate,
        isCumulative ? "total" : "current"
      );
      // Set result from parsing data into this state
      this.setState({
        indicatorData: data.indicators,
        iconColors: data.iconColors,
        parsedReportingPeriods: true
      });
    }
  }

  dispatch_data(e) {
    let { company, baseMetrics } = this.props;
    let { projectId, refId, project } = this.props;
    let { fromDate, toDate } = this.state;
    fromDate = fromDate.format();
    toDate = toDate.format();
    let companyId = company.get("uid");

    let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });

    fetch.updateFetchParams({
      body: {
        // Temporary solution to get everything under baseMetrics.id.name, baseMetrics.id.category and the respective scores
        type: "ASO_METRIC_PDF",
        payload: {
          baseMetrics: baseMetrics,
          project: project.get("name"),
          projectId: projectId
        }
      }
    });

    Swal.queue([
      {
        title: "Compiling Report",
        confirmButtonText: "Download Report",
        html: `<p>This may take several minutes depending on the amount of indicators and metrics.</p><p>Please be patient.</p>`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch({ updateBody: true })
            .then(response => {
              if (response.status == 500) {
                return;
              }
              if (response) return response.blob();
            })
            .then(data => {
              let blobUrl = window.URL.createObjectURL(data);
              Swal.insertQueueStep({
                showConfirmButton: false,
                showCancelButton: false,
                title: "Download ready",
                html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="ASO_RSSB_REPORT.pdf">Download</a>`
              });
            })
            .catch(err => {
              console.log(err);
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                text: "There was an error compiling your results.  Please contact support if the problem persists."
              });
            });
        }
      }
    ]);
  }

  downloadExcel(e, apiId, filename = "project_report.xlxs") {
    e.preventDefault();
    let { projectId, refId } = this.props;
    let { fromDate, toDate } = this.state;
    fromDate = fromDate.format();
    toDate = toDate.format();
    let fetch = new Traec.Fetch(apiId, "list", {
      projectId,
      refId,
      fromDate,
      toDate,
      format: "excel"
    });
    Swal.queue([
      {
        title: "Downloading",
        confirmButtonText: "Generate Excel Report",
        html:
          "<p>Pulling dashboard data into an Excel report.</p> <p>This involved aggregating a large amout of data and may take several minutes depending on the size of your project, reporting packages, and reported values.</p><p>Please be patient.</p>",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch()
            .then(response => response.blob())
            .then(data => {
              let blobUrl = window.URL.createObjectURL(data);
              Swal.insertQueueStep({
                showConfirmButton: false,
                showCancelButton: false,
                title: "Download ready",
                html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="${filename}">Download</a>`
              });
            })
            .catch(err => {
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                text: "There was an error generating your report.  Please contact support if the problem persists."
              });
            });
        }
      }
    ]);
  }

  setSelectedSustainabilityIssue(issue) {
    this.setState({ selectedSustainabilityIssue: issue });
  }

  setSelectedIndicator(indicator) {
    this.setState({ selectedIndicators: indicator });
  }

  /**********************
   RENDER METHODS
   **********************/

   render() {
    const {
      company,
      project,
      projectId,
      cref,
      crefId,
      isRootRef,
      isResponsible,
      canReadProject,
      canReadRefs,
      latestCommitIndicators,
      iconPath
    } = this.props;

    console.log("AAA RENDERING PROJECT", isRootRef, isResponsible, canReadProject, canReadRefs);
    if (isRootRef === false && isResponsible === false && (!canReadProject || !canReadRefs)) {
      return <PermissionWarning />;
    }

    const { _projectId, _refId } = this.props.match.params;
    if (!project || !cref || !crefId || (_refId && !crefId.startsWith(_refId))) {
      return (
        <div className="container-fluid mt-5">
          <Spinner title="Loading Data..." timedOutComment={<PermissionWarning />} />
        </div>
      );
    }

    return (
      <ProjectPermission
        projectId={projectId}
        requiresAdmin={false}
        requiredActions={isRootRef ? ["READ_PROJECT_REPORT"] : []}
        showWarning={true}
      >
        {/*<h3>{isRootRef ? "Project" : "Reporting Package"} Dashboard - Overview</h3>*/}
        <div style={{ fontSize: "1.15rem" }}>
          <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />
        </div>

        <ReportingPeriodRangePicker
          filters={this.state.filters}
          setFilters={(v) => this.setState({filters:  v})}
          onChange={({fromDate, toDate}) => {
            this.setState({fromDate: new Moment(fromDate)});
            this.setState({toDate: new Moment(toDate)});
          }}
        />

        {/* Render the charts */}
        <BSBtnDropdown
          header="Dashboard menu"
          links={[
            { name: "Export Indicators to Excel", onClick: () => fetchIndicatorExport({
              projectId: _projectId, refId: _refId || "root", period: "project", cumulative: false, 
              fileName: `indicators.csv`}
            )},
            { name: "Export Indicators to Excel (cumulative)", onClick: () => fetchIndicatorExport({
              projectId: _projectId, refId: _refId || "root", period: "project", cumulative: true, 
              fileName: `indicators.csv`}
            )},            { name: "Export Inputs to Excel", onClick: e => this.downloadExcel(e, "project_inputs", "project_inputs.csv") },
            { name: null },
            { name: `${this.state.showColorScale ? "Hide" : "Show"} colour scale`, onClick: this.toggleColorScale }
          ]}
        />
        <div style={{ clear: "both" }} />

        <div className="row">
          <div className="col-sm-12">
            <ColorScale show={this.state.showColorScale} />
          </div>
        </div>

        <IndicatorWarning indicators={latestCommitIndicators} />

        <DashboardComponents
          projectId={projectId}
          refId={isRootRef ? "root" : cref.get("uid")}
          indicators={latestCommitIndicators}
          iconPath={iconPath}
          filters={this.state.filters}
        />

      </ProjectPermission>
    );
  }
}

const isApprover = ({ state, userPermission, commit, projectId }) => {
  // Get the project disciplines in a map
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`) || Traec.Im.List();
  if (!projectDisciplines || !commit) {
    return false;
  }
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Traec.Im.Map());
  // Check if we are an approver
  let disciplineId = commit.get("discipline");
  let projectDiscipline = projectBaseDisciplineMap.get(disciplineId);
  if (!projectDiscipline) {
    return false;
  }
  //
  let approverId = projectDiscipline.get("approver");
  let approverProjectDiscipline = projectDisciplines.get(approverId);
  if (approverProjectDiscipline && userPermission) {
    return userPermission.get("projectDisciplineIds").contains(approverId);
  }
  //
  return false;
};

const mapStateToProps = (state, ownProps) => {
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, rootRef, rootRefId, isRootRef } = getProjectProps(
    state,
    projectId,
    refId
  );

  // Get the project reporting periods
  let prPath = `entities.projectReportingPeriods.byId.${projectId}`;
  if (!isRootRef) {
    prPath = `entities.projectReportingPeriods.ref.${refId}.byId.${projectId}`;
  }

  let projectReportingPeriods = state.getInPath(prPath);

  let selectedProjectReportingPeriods = projectReportingPeriods
    ? projectReportingPeriods
        .toList()
        .sortBy(i => i.get("startDate"))
        .reverse()
        .slice(0, 6)
        .reverse()
    : null;

  // let baseMetricIds = getMetricIdsFromIndicator({ indicator });
  let baseMetrics = state.getInPath("entities.baseMetrics.byId");

  // Get the targets for the latest commit
  let commitId = cref?.getInPath("latest_commit.uid");
  let latestCommitTargets = state.getInPath(`entities.commitEdges.byId.${commitId}.metricTargets`);
  let latestCommitIndicators = state.getInPath(`entities.commitEdges.byId.${commitId}.indicators`);

  // ONLY ALLOW RESPONSIBLE PEOPLE OF THIS
  // Get the project permissions for this user
  let commit = cref?.get("latest_commit");
  let userProjectPermissions = state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`);
  let canReadRefs = (userProjectPermissions?.get("actions") || Traec.Im.Set()).has("READ_TRACKER_REF");
  let canReadProject = (userProjectPermissions?.get("actions") || Traec.Im.Set()).has("READ_PROJECT_REPORT");

  let isResponsible = userProjectPermissions?.get("is_admin");
  if (commit && !isResponsible) {
    let responsibleDiscipline = commit.get("discipline");
    let userDisciplines = userProjectPermissions ? userProjectPermissions.get("baseDisciplineIds") : Traec.Im.Map();
    if (userDisciplines.contains(responsibleDiscipline)) {
      isResponsible = true;
      //console.log("This user is responsible for this work package")
    }
  }

  // Finally check to see if we are an approver of this discipline
  if (commit && !isResponsible) {
    isResponsible = isApprover({ state, userPermission: userProjectPermissions, commit, projectId });
  }

  // Download file
  let downloadUrl = state.getInPath(`entities.download.byId.${projectId}`);

  // Are we using a cumulative or latest dashboard data
  let isCumulative = state.getInPath(`ui.dashboards.${projectId}.cumulativeValues`);
  isCumulative = isCumulative === undefined ? true : isCumulative;

  let iconPath = state.getInPath(`ui.styles.iconPath`)

  return {
    company,
    projectId,
    project,
    trackerId,
    tracker,
    rootRef,
    refId,
    cref,
    crefId,
    isRootRef,
    rootRefId,
    projectReportingPeriods,
    selectedProjectReportingPeriods,
    latestCommitTargets,
    latestCommitIndicators,
    isResponsible,
    canReadProject,
    canReadRefs,
    downloadUrl,
    commitId,
    isCumulative,
    baseMetrics,
    iconPath
  };
};

export default connect(mapStateToProps)(ProjectHome);
